import { EditorSDK, EditorReadyOptions } from '@wix/platform-editor-sdk';
import { IFedOpsLogger } from '@wix/native-components-infra/dist/src/types/types';
import {
  EcomComponent,
  addComponents,
  installCartIcon,
  EcomComponentConfiguration
} from '@wix/ecom-platform-sdk';
import { removeBookCheckoutPageOnEcom } from '../pages-panel-actions';
import { addBookingCheckoutPage } from '../pages-actions';
import {
  ecomRolloutButNotSite,
  isEcomCartInstalled,
} from '../editor-sdk-actions';
import { EditorScriptApi } from '../../api/api';
import { isExperimentEnabled } from '../experiments';
import { experiments } from '../../constants';
import {
  updateIsCartEnabledBusinessProperty,
  getIsCartEnabledBusinessProperty,
} from '../migrate-actions';
import {
  openCartSuccessfullyInstalledModal,
  openEcomMigrationProposalModal,
  openEcomHistoryBlockModal,
} from '../migration-modals';
import { migrateAction, MigrateAction } from '../../migration';
import { Translations } from '../translations';
import {Actions, reportBi} from "../editor-script-bi-logger";

interface EcomInstallationParams {
  isFirstInstall: boolean;
  editorOrigin: string;
  msid: string;
  _editorSDK: EditorSDK;
  editorScriptApi: EditorScriptApi;
  appToken?: string;
  editorTranslation: Translations;
  fedopsLogger: IFedOpsLogger;
  migrationActionInput?: MigrateAction;
  instance: string;
  locale?: any;
}

interface MigrateCartParams {
  editorSdk: EditorSDK;
  editorScriptApi: EditorScriptApi;
  instance: string;
}

interface InstallCart {
  editorSdk: EditorSDK;
  editorScriptApi: EditorScriptApi;
  instance: string;
  msid: string;
  editorOrigin: string;
  editorOptions: EditorReadyOptions;
  fedopsLogger: IFedOpsLogger;
  locale?: any;
}

interface MarkCartEnabled {
  editorSdk: EditorSDK;
  editorScriptApi: EditorScriptApi;
  isFirstInstall: boolean;
  instance: string;
  msid: string;
  editorOrigin: string;
  fedopsLogger: IFedOpsLogger;
}

export const markAsEcom = async ({
  isEcomReady,
  editorScriptApi,
  editorSdk,
  appToken,
}): Promise<boolean> => {
  if (!isEcomReady) {
    const isReady = await editorScriptApi.setEcomCompatible();
    if (isReady) {
      await removeBookCheckoutPageOnEcom(
        editorSdk,
        appToken,
        editorScriptApi,
      );
    } else {
      await addBookingCheckoutPage(editorSdk, appToken);
    }
    return isReady;
  }
};

export const skipEcomInstallationAction = ({
  _editorSDK,
  editorScriptApi,
  instance,
  locale,
  editorTranslation,
  fedopsLogger,
  msid,
  isFirstInstall,
  editorOrigin,
  migrationActionInput,
}: EcomInstallationParams) => {
  return async () => {
    if (
      !isFirstInstall &&
      !(await editorScriptApi.getIsBookingsOnEcom())
    ) {
      openEcomMigrationProposalModal(
        _editorSDK,
        editorScriptApi,
        locale,
        fedopsLogger,
        msid,
        isFirstInstall,
        editorOrigin,
        instance,
        editorTranslation,
        { ...migrationActionInput, isSelfMigration: true },
      );
      return true;
    }

    return false;
  };
};

export const requiredEcomComponentsAction = (skipEcomInstallation:  () => Promise<boolean>): () => Promise<EcomComponentConfiguration> => {
  return async (): Promise<EcomComponentConfiguration> => {
    return {
      shouldSkipEcomInstallation: await skipEcomInstallation(),
      shouldInstallMembersArea: !(await isExperimentEnabled(experiments.SKIP_MEMBER_INSTALLATION)),
      ecomComponents: {
        [EcomComponent.CHECKOUT]: {
          isInstalled: true
        },
        [EcomComponent.THANK_YOU_PAGE]: {
          isInstalled: true
        },
        [EcomComponent.CART]: {
          isInstalled: true
        },
        [EcomComponent.CART_ICON]: {
          isInstalled: true
        }
      }
    };
  }
};

export const installEcomCartIcon = async ({
  editorSdk,
  editorOptions,
  editorScriptApi,
  locale,
  instance,
}: InstallCart) => {
  if (editorOptions?.appUrlQueryParams?.isCartFirstInstall) {
    if (!editorOptions?.firstInstall) {
      await installCartIcon(editorSdk);
    }

    openCartSuccessfullyInstalledModal(
      editorSdk,
      editorScriptApi,
      locale,
      instance,
    );
  }
};

export const installCart = async ({
  editorOptions,
  editorScriptApi,
  instance,
  msid,
  locale,
  editorSdk,
  fedopsLogger,
  editorOrigin
}: InstallCart) => {
  try {
    fedopsLogger.interactionStarted(Actions.CART_INSTALLATION);
    const isCartEnabledFT = await isExperimentEnabled(experiments.CART_ENABLED);

    if (isCartEnabledFT) {
      await installEcomCartIcon({
        editorOptions,
        editorScriptApi,
        instance,
        locale,
        editorSdk,
        fedopsLogger,
        msid,
        editorOrigin
      });
      await markCartEnabled({
        editorSdk,
        editorScriptApi,
        instance,
        fedopsLogger,
        isFirstInstall: editorOptions?.firstInstall,
        msid,
        editorOrigin
      })
    }
    fedopsLogger.interactionEnded(Actions.CART_INSTALLATION);
  } catch (e) {
    const errorMessage = `${
      e?.message ? e.message : JSON.stringify(e)
    } - Action ${Actions.CART_INSTALLATION}`;
    reportBi({
      msid,
      origin: editorOrigin,
      isFirstInstall: editorOptions?.firstInstall,
      actionType: Actions.CART_INSTALLATION,
      actionSucceed: false,
      errorMessage,
    });
    throw new Error(errorMessage);
  }
};

export const markCartEnabled = async ({
  editorScriptApi,
  isFirstInstall,
  instance,
  msid,
  editorOrigin,
  fedopsLogger
}: MarkCartEnabled) => {
  try {
    fedopsLogger.interactionStarted(Actions.MARK_CART_ENABLED);
    const isCartEnabled: boolean | undefined = await getIsCartEnabledBusinessProperty(editorScriptApi, instance);

    // undefined meaning this flag never initiate, and we should initiate for first install to true
    if (isFirstInstall && isCartEnabled === undefined) {
      await updateIsCartEnabledBusinessProperty(
        editorScriptApi,
        instance,
        true,
      );
    }
    fedopsLogger.interactionEnded(Actions.MARK_CART_ENABLED);
  } catch (e) {
    const errorMessage = `${
      e?.message ? e.message : JSON.stringify(e)
    } - instance ${instance}`;
    reportBi({
      msid,
      origin: editorOrigin,
      isFirstInstall,
      actionType: Actions.MARK_CART_ENABLED,
      actionSucceed: false,
      errorMessage,
    });
    console.log('mark enabled cart failed', e);
  }
}

export const validateAndFixEcomSite = async ({
  isFirstInstall,
  _editorSDK,
  editorScriptApi,
  appToken,
  locale,
  fedopsLogger,
  msid,
  editorOrigin,
  migrationActionInput,
}: EcomInstallationParams) => {
  try {
    if (!isFirstInstall && migrationActionInput) {
      const isEcomRolloutButNotSite = await ecomRolloutButNotSite(
        _editorSDK,
        editorScriptApi,
        appToken!,
      );

      if (isEcomRolloutButNotSite) {
        fedopsLogger.interactionStarted(Actions.FIX_SITE);
        await migrateAction(migrationActionInput);
        fedopsLogger.interactionEnded(Actions.FIX_SITE);
      }
    }
  } catch (e) {
    openEcomHistoryBlockModal(
      _editorSDK,
      editorScriptApi,
      locale,
      migrationActionInput.instance,
    );

    const errorMessage = `${
      e?.message ? e.message : JSON.stringify(e)
    } - Action ${Actions.FIX_SITE}`;
    reportBi({
      msid,
      origin: editorOrigin,
      isFirstInstall,
      actionType: Actions.FIX_SITE,
      actionSucceed: false,
      errorMessage,
    });
  }
};

export const migrateCart = async ({
  editorSdk,
  editorScriptApi,
  instance,
}: MigrateCartParams) => {
  const isBookingRollout = await editorScriptApi.getIsBookingsOnEcom();

  if (!isBookingRollout) {
    throw new Error('migrateCart - Site are not Rollout');
  }

  try {
    await updateIsCartEnabledBusinessProperty(editorScriptApi, instance, true);
  } catch (e) {
    const errorMessage = `${
      e?.message ? e.message : JSON.stringify(e)
    } | migrateCart - updateIsCartEnabledBusinessProperty - errorCode: 8ad89f0b-96c9-4538-9158-329418318132`;
    throw new Error(errorMessage);
  }

  const isCartInstalled = !!(await isEcomCartInstalled(editorSdk));

  if (!isCartInstalled) {
    try {
      await addComponents(editorSdk, [EcomComponent.CART]);
    } catch (e) {
      const errorMessage = `${
        e?.message ? e.message : JSON.stringify(e)
      } | migrateCart - addComponents - errorCode: 4589f0b-96c9-4538-9158-329418398312`;
      throw new Error(errorMessage);
    }
  }

  console.log('Cart Migration Success');
};
